import React, { FC } from 'react';
import SpectarLayout from '../../../components/PagesLayouts/SpectarLayout';
import { TText } from '../../../types/types';
import SectionTitle from '../../../components/common/SectionTitle';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import ListItem from '../../../components/pagesComponents/CaseStudy/ListItem';
import FixedButton from '../../../components/common/FixedButton';

const OtherIndustriesRelevance: FC = () => {
    const industriesText: TText = [
        {
            id: 0,
            text: 'Any AR-focused application with support of Microsoft HoloLens.',
            margin: 'mt',
        },
        { id: 1, text: 'If your product or service utilizes Microsoft Azure App Services.' },
        { id: 2, text: 'REST API development for own microservices or SaaS products.' },
    ];

    return (
        <SpectarLayout>
            <div className="statflo-case-study__text statflo-case-study__text--large">
                <SectionTitle type="primary" title="Others industries relevance" />
                {industriesText.map((industry) => (
                    <ListItem key={industry.id} margin={industry.margin}>
                        {industry.text}
                    </ListItem>
                ))}

                <Links />
            </div>
            <FixedButton />
        </SpectarLayout>
    );
};

export default OtherIndustriesRelevance;
